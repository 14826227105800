import React from 'react'

import { Link } from 'gatsby'
import Layout from '../../components/english_layout'
import SEO from '../../components/seo'
import Banner from '../../components/banner'
import Foot from '../../components/footer'

import '../../styles/resources.sass'

// Note: Only this file (and the english version) is called resource without the 's'. 
// It's because Gatsby doesn't seem to accept the path url/resources for some reason

const ResourcesPage = () => {
    return (
        <Layout languageSwitchLink="/resource">
            <SEO title="Ressources" />
            <Banner class_suffix="deep"/>
            <div className="resources-container">
                <div className="resources-content">
                    <p>
                        We highly recommend that you first give a look to learning platforms such as <a href="https://tryhackme.com/paths">Try Hack Me</a>, <a href="https://academy.hackthebox.com/">Hack the Box Academy</a> and <a href="https://picoctf.org/">picoCTF</a> to learn the basics. Then, if you want to dive deeper into some specific topics, here are some resources to help you.
                    </p>
                    <p>
                        If you come across the term "CTF" in the following content and find yourself wondering what it means, don't hesitate to turn to Google! In cybersecurity, Google is your best friend, as you can't possibly be expected to know everything. Begin your journey of learning now!
                    </p>
                    <div className="table-of-contents">
                        <h1>Table of Contents</h1>
                        <ul>
                            <li><a href="#resources-for-ctfs">Resources for CTFs</a></li>
                            <ul>
                                <li><a href="#general-ctf">General CTF platforms</a></li>
                                <li><a href="#web-exploitation">Web Exploitation</a></li>
                                <li><a href="#active-directory">Active Directory</a></li>
                                <li><a href="#cryptography">Cryptography</a></li>
                                <li><a href="#digital-forensics">Digital Forensics</a></li>
                                <li><a href="#reverse-engineering">Reverse Engineering & Malware Analysis</a></li>
                                <li><a href="#binary-exploitation">Binary Exploitation</a></li>
                                <li><a href="#offensive-security">Offensive Security</a></li>
                                <li><a href="#defensive-security">Defensive Security</a></li>
                                <li><a href="#hardware">Hardware</a></li>
                                <li><a href="#ics-ot">Industrial Control Systems (ICS)/Operational Technologies (OT)</a></li>
                                <li><a href="#cloud-security">Cloud Security</a></li>
                                <li><a href="#osint">OSINT</a></li>
                                <li><a href="#blockchain">Blockchain</a></li>
                            </ul>
                            <li><a href="other-cybersecurity-resources">Other Cybersecurity Resources</a></li>
                            <ul>
                                <li><a href="#blogs">Blogs</a></li>
                                <li><a href="#community-events">Community Events</a></li>
                                <li><a href="#podcasts">Podcasts</a></li>
                                <li><a href="#trainings">Trainings</a></li>
                                <li><a href="#youtube-channels">Youtube Channels</a></li>
                                <li><a href="#magazines">Magazines</a></li>
                                <li><a href="#reddit">Reddit</a></li>
                                <li><a href="#github-repos">Github Repos</a></li>
                                <li><a href="#write-ups">Write-ups</a></li>
                            </ul>
                        </ul>
                    </div>

                    <hr></hr>

                    <h1 id="resources-for-ctfs">Resources for CTFs</h1>
                    <h2 id="general-ctf">General CTF platforms</h2>
                    <ul>
                        <li>
                            <a href="https://tryhackme.com">TryHackMe</a>
                        </li>
                        <li>
                            <a href="https://play.picoctf.org">PicoCTF (PicoGym)</a>
                        </li>
                        <li>
                            <a href="https://app.hackthebox.com">HackTheBox</a>
                        </li>
                        <li>
                            <a href="https://overthewire.org">OverTheWire</a>
                        </li>
                        <li>
                            <a href="https://underthewire.tech/">UnderTheWire</a>
                        </li>
                        <li>
                            <a href="https://ringzer0ctf.com">RingZer0</a>
                        </li>
                        <li>
                            <a href="http://io.netgarage.org/">IO</a>
                        </li>
                        <li>
                            <a href="https://hackropole.fr">Hackropole</a>
                        </li>
                    </ul>

                    <h2 id="web-exploitation">Web Exploitation</h2>
                    <p>Online Platforms:</p>
                    <ul>
                        <li>
                            <a href="https://portswigger.net/web-security/all-labs">Portswigger's Web Security Academy</a>
                        </li>
                        <li>
                            <a href="https://xss-game.appspot.com/">XSS Games</a>
                        </li>
                    </ul>
                    <p>Other resources:</p>
                    <ul>
                        <li>
                            <a href="https://owasp.org/www-project-vulnerable-web-applications-directory/">OWASP Vulnerable Web Applications Directory</a>
                        </li>
                        <li>
                            <a href="https://owasp.org/www-project-top-ten/">OWASP Top Ten Web Application Security Risks</a>
                        </li>
                        <li>
                            <a href="https://www.pentesteracademy.com/">Pentester Academy</a>
                        </li>
                        <li>
                            <a href="https://hackerone.com/hacktivity">Hackivity on HackerOne</a>
                        </li>
                        <li>
                            <a href="https://github.com/dolevf/Damn-Vulnerable-GraphQL-Application">Damn Vulnerable GraphQL Application</a>
                        </li>
                    </ul>


                    <h2 id="active-directory">Active Directory</h2>
                    <p>Github Repo:</p>
                    <ul>
                        <li>
                            <a href="https://github.com/Orange-Cyberdefense/GOAD">GOAD</a>
                        </li>
                    </ul>
                    <p>Other resources:</p>
                    <ul>
                        <li>
                            <a href="https://www.thehacker.recipes/">The Hacker Recipes</a>
                        </li>
                    </ul>

                    <h2 id="cryptography">Cryptography</h2>
                    <p>Online Platforms:</p>
                    <ul>
                        <li>
                            <a href="https://cryptohack.org/">CryptoHack</a>
                        </li>
                    </ul>
                    <p>Tools:</p>
                    <ul>
                        <li>
                            <a href="https://gchq.github.io/CyberChef/">Cyberchef</a>
                        </li>
                    </ul>
                    <p>Books:</p>
                    <ul>
                        <li>
                            Serious Cryptography (No starch Press, 2017)
                        </li>
                    </ul>

                    <h2 id="digital-forensics">Digital Forensics</h2>
                    <p>Youtube Channels:</p>
                    <ul>
                        <li>
                            <a href="https://www.youtube.com/13cubed">13Cubed</a>
                        </li>
                    </ul>

                    <h2 id="reverse-engineering">Reverse Engineering & Malware Analysis</h2>
                    <p>Online Platforms:</p>
                    <ul>
                        <li>
                            <a href="https://crackmes.one/">CrackMe</a>
                        </li>
                        <li>
                            <a href="https://challenges.re/">Challenges.re</a>
                        </li>
                        <li>
                            <a href="https://www.root-me.org">Root Me</a>
                        </li>
                    </ul>
                    <p>Github Repos:</p>
                    <ul>
                        <li>
                            <a href="https://github.com/wtsxDev/reverse-engineering">Github - wtsxDev/reverse-engineering</a>
                        </li>
                        <li>
                            <a href="https://github.com/Hustcw/Angr_Tutorial_For_CTF">Github - Hustcw/Angr_Tutorial_For_CTF</a>
                        </li>
                    </ul>
                    <p>CTF Competitions:</p>
                    <ul>
                        <li>
                            <a href="https://flare-on.com/">Flare-On Challenge</a>
                        </li>
                    </ul>
                    <p>Tools:</p>
                    <ul>
                        <li>
                            <a href="https://dogbolt.org/">Dogbolt - Decompiler Explorer</a>
                        </li>
                    </ul>
                    <p>Other resources:</p>
                    <ul>
                        <li>
                            <a href="https://beginners.re/">Beginners.re</a>
                        </li>
                    </ul>

                    <h2 id="binary-exploitation">Binary Exploitation (pwn)</h2>
                    <p>Online Platforms:</p>
                    <ul>
                        <li>
                            <a href="https://pwnable.kr/">pwnable.kr</a>
                        </li>
                        <li>
                            <a href="https://pwnable.tw/">pwnable.tw</a>
                        </li>
                        <li>
                            <a href="https://pwn.college/">pwn college</a>
                        </li>
                        <li>
                            <a href="https://ropemporium.com/">ROP Emporium</a>
                        </li>
                        <li>
                            <a href="http://smashthestack.org/">Smash the stack</a>
                        </li>
                    </ul>
                    <p>Github Repo:</p>
                    <ul>
                        <li>
                            <a href="https://github.com/shellphish/how2heap">How2Heap</a>
                        </li>
                    </ul>

                    <h2 id="offensive-security">Offensive Security (Penetration Testing & Red Teaming)</h2>
                    <p>Online Platforms:</p>
                    <ul>
                        <li>
                            <a href="https://www.damnvulnerabledefi.xyz/">Damn Vulnerable DeFi</a>
                        </li>
                        <li>
                            <a href="https://www.hackthebox.eu/">Hack the Box</a>
                        </li>
                    </ul>
                    <p>Learning Platforms:</p>
                    <ul>
                        <li>
                            <a href="https://github.com/madhuakula/kubernetes-goat">Kubernetes Goat</a>
                        </li>
                        <li>
                            <a href="http://flaws.cloud/">flAWS challenge</a>
                        </li>
                        <li>
                            <a href="https://github.com/dolevf/Damn-Vulnerable-GraphQL-Application">Damn Vulnerable GraphQL Application</a>
                        </li>
                    </ul>
                    <p>Books:</p>
                    <ul>
                        <li>
                            Black Hat Go (No Starch Press, 2019)
                        </li>
                        <li>
                            Foundations of Information Security (No Starch Press, 2019)
                        </li>
                        <li>
                            Gray Hat Hacking: The Ethical Hacker's Handbook, Fifth Edition (McGraw-Hill, 2018)
                        </li>
                        <li>
                            The Hacker Playbook 3 (Peter Kim, 2018)
                        </li>
                        <li>
                            Pentesting Azure Application (No Starch Press, 2018)
                        </li>
                        <li>
                            Attacking Netwok Protocols (No Starch Press, 2018)
                        </li>
                        <li>
                            Nmap: Network Exploration and Security Auditing Cookbook (Packt publisher, 2018)
                        </li>
                        <li>
                            Gray Hat C# (No Starch Press, 2017)
                        </li>
                    </ul>
                    <p>Other resources:</p>
                    <ul>
                        <li>
                            <a href="https://book.hacktricks.xyz/">Hacktricks</a>
                        </li>
                        <li>
                            <a href="https://github.com/swisskyrepo/PayloadsAllTheThings">Payload all the things</a>
                        </li>
                    </ul>

                    <h2 id="defensive-security">Defensive Security (Blue Team)</h2>
                    <p>Other resources:</p>
                    <ul>
                        <li>
                            <a href="https://bots.splunk.com/login?redirect=/">Splunk's Boss of the SCC</a>
                        </li>
                        <li>
                            <a href="https://securityblue.team/">Security Blue Team</a>
                        </li>
                    </ul>

                    <h2 id="hardware">Hardware</h2>
                    <p>Online Platforms:</p>
                    <ul>
                        <li>
                            <a href="https://microcorruption.com">MicroCorruption</a>
                        </li>
                    </ul>
                    <p>Youtube Channel:</p>
                    <ul>
                        <li>
                            <a href="https://youtube.com/playlist?list=PLhixgUqwRTjwNaT40TqIIagv3b4_bfB7M">riscure Embedded Hardware CTF - RHme</a>
                        </li>
                    </ul>

                    <h2 id="ics-ot">Industrial Control Systems (ICS)/Operational Technologies (OT)</h2>
                    <p>Github Repo:</p>
                    <ul>
                        <li>
                            <a href="https://github.com/dfrws/dfrws2023-challenge/tree/main">The DFRWS 2023 challenge (The Troubled Elevator)</a>
                        </li>
                    </ul>

                    <h2 id="cloud-security">Cloud Security</h2>
                    <p>Github Repo:</p>
                    <ul>
                        <li>
                            <a href="https://github.com/madhuakula/kubernetes-goat">Kubernetes Goat</a>
                        </li>
                    </ul>
                    <p>Online Platform:</p>
                    <ul>
                        <li>
                            <a href="http://flaws.cloud/">flAWS challenge</a>
                        </li>
                    </ul>

                    <h2 id="osint">OSINT</h2>
                    <p>Online Platforms:</p>
                    <ul>
                        <li>
                            <a href="https://ozint.eu/">OZINT</a>
                        </li>
                    </ul>
                    <p>Other resources:</p>
                    <ul>
                        <li>
                            <a href="https://github.com/ninoseki/shodan-dojo">Github - ninoseki/shodan-dojo</a>
                        </li>
                        <li>
                            <a href="https://osintframework.com/">OSINT Framework</a>
                        </li>
                    </ul>

                    <h2 id="blockchain">Blockchain</h2>
                    <p>Online Platforms:</p>
                    <ul>
                        <li>
                            <a href="https://capturetheether.com/">Capture the Ether</a>
                        </li>
                        <li>
                            <a href="https://chainflag.org/">ChainFlag</a>
                        </li>
                        <li>
                            <a href="https://cryptozombies.io/">CryptoZombies</a>
                        </li>
                        <li>
                            <a href="https://www.damnvulnerabledefi.xyz/">Damn Vulnerable DeFi</a>
                        </li>
                        <li>
                            <a href="https://ethernaut.openzeppelin.com/">The Ethernaut</a>
                        </li>
                    </ul>
                    <p>Other resources:</p>
                    <ul>
                        <li>
                            <a href="https://ethereum.org/en/developers/learning-tools/">Ethereum Learning Tools</a>
                        </li>
                    </ul>

                    <hr></hr>

                    <h1 id="other-cybersecurity-resources">Other Cybersecurity Resources</h1>

                    <h2 id="blogs">Blogs</h2>
                    <ul>
                        <li>
                            <a href="https://polycyber.io/blog">PolyCyber's Blog</a>
                        </li>
                        <li>
                            <a href="https://portswigger.net/daily-swig">The Daily Swig</a>
                        </li>
                        <li>
                            <a href="https://hacks.mozilla.org/">Mozilla Hack</a>
                        </li>
                        <li>
                            <a href="https://googleprojectzero.blogspot.com/">Google Project Zero</a>
                        </li>
                        <li>
                            <a href="https://blog.detectify.com/">Detectify Blog</a>
                        </li>
                        <li>
                            <a href="https://securitylab.github.com/">Github Security Blog</a>
                        </li>
                    </ul>

                    <h2 id="community-events">Community Events</h2>
                    <ul>
                        <li>
                            <a href="https://montrehack.ca/">MontreHack</a>
                        </li>
                        <li>
                            <a href="https://www.meetup.com/find/?keywords=hack%20the%20box">Hack the Box Community Meetups</a>
                        </li>
                        <li>
                            <a href="https://owasp.org/events/">OWASP Events</a>
                        </li>
                    </ul>

                    <h2 id="podcasts">Podcasts</h2>
                    <ul>
                        <li>
                            <a href="https://darknetdiaries.com/">Darknet Diaries</a>
                        </li>
                        <li>
                            <a href="https://malicious.life/">Malicious Life</a>
                        </li>
                        <li>
                            <a href="https://thecyberwire.com/podcasts/daily-podcast">CyberWire Daily</a>
                        </li>
                        <li>
                            <a href="https://therecord.media/podcast">Click Here</a>
                        </li>
                        <li>
                            <a href="https://isc.sans.edu/podcast.html">SANS Internet Stormcenter Daily Cyber Security Podcast (Stormcast)</a>
                        </li>
                        <li>
                            <a href="https://twit.tv/shows/security-now">Security Now</a>
                        </li>
                        <li>
                            <a href="https://podcasts.apple.com/us/podcast/the-defenders-advantage-podcast/id1073779629">The Defender's Advantage</a>
                        </li>
                    </ul>

                    <h2 id="trainings">Trainings</h2>
                    <ul>
                        <li>
                            <a href="https://www.bugcrowd.com/resources/levelup/">Bug Crowd's Level Up</a>
                        </li>
                        <li>
                            <a href="https://www.hackerone.com/hacktivitycon">H@ckivitycon</a>
                        </li>
                        <li>
                            <a href="https://redteamvillage.io/">DEFCON's Red Team Village</a>
                        </li>
                        <li>
                            <a href="https://www.hacker101.com/">Hacker101</a>
                        </li>
                        <li>
                            <a href="https://www.cybrary.it/">Cybrary</a>
                        </li>
                        <li>
                            <a href="https://www.bugcrowd.com/hackers/bugcrowd-university/">Bug Crowd University</a>
                        </li>
                    </ul>

                    <h2 id="youtube-channels">Youtube Channels</h2>
                    <ul>
                        <li>
                            <a href="https://securitycreators.video/">Great list of Infosec Youtubers</a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/channel/UCa6eh7gCkpPo5XXUDfygQQA">Ippsec - Hack the box walkthrough videos</a>
                        </li>
                    </ul>

                    <h2 id="magazines">Magazines</h2>
                    <ul>
                        <li>
                            <a href="http://www.phrack.org/issues/">Phrack Magazine</a>
                        </li>
                        <li>
                            <a href="https://pagedout.institute/">Paged Out Institute</a>
                        </li>
                    </ul>

                    <h2 id="reddit">Reddit</h2>
                    <ul>
                        <li>
                            <a href="https://www.reddit.com/r/cybersecurity/">r/cybersecurity</a>
                        </li>
                        <li>
                            <a href="https://www.reddit.com/r/securityCTF/">r/securityCTF</a>
                        </li>
                        <li>
                            <a href="https://www.reddit.com/r/AskNetSec/">r/AskNetSec</a>
                        </li>
                        <li>
                            <a href="https://www.reddit.com/r/LiveOverflow/">r/LiveOverflow</a>
                        </li>
                        <li>
                            <a href="https://www.reddit.com/r/bugbounty/">r/bugbounty</a>
                        </li>
                        <li>
                            <a href="https://www.reddit.com/r/netsecstudents/">r/netsecstudents</a>
                        </li>
                        <li>
                            <a href="https://www.reddit.com/r/oscp/">r/oscp</a>
                        </li>
                        <li>
                            <a href="https://www.reddit.com/r/netsec/">r/netsec</a>
                        </li>
                    </ul>

                    <h2 id="github-repos">Github Repos</h2>
                    <ul>
                        <li>
                            <a href="https://github.com/vitalysim/Awesome-Hacking-Resources">Awesome Hacking Resources</a>
                        </li>
                        <li>
                            <a href="https://github.com/apsdehal/awesome-ctf">Awesome CTF</a>
                        </li>
                        <li>
                            <a href="https://github.com/qazbnm456/awesome-web-security">Awesome Web Security</a>
                        </li>
                        <li>
                            <a href="https://github.com/jassics/security-study-plan">Practical Cybersecurity Engineerg Study Plan</a>
                        </li>
                    </ul>

                    <h2 id="write-ups">Write-ups</h2>
                    <ul>
                        <li>
                            <a href="https://ctftime.org/writeups">CTFTime Write-ups</a>
                        </li>
                        <li>
                            <a href="https://medium.com/bugbountywriteup">InfoSec Write-ups</a>
                        </li>
                        <li>
                            <a href="https://hackerone.com/hacktivity">Hackivity on HackerOne</a>
                        </li>
                        <li>
                            <a href="https://blog.intigriti.com/">Intigriti's Blog</a>
                        </li>
                    </ul>
                </div>
            </div>
            <Foot></Foot>
        </Layout>
    )
}

export default ResourcesPage
